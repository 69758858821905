import React from "react"

const CheckoutAccountManagement = () => {
  return (
    <div className="section-checkout-account-management">
      <div className="container">
        <div className="content-wrapper">
          <h2 className="title">Simple and beautiful checkout and account management</h2>
          <p>Your clients will find it easy to create their account, change their plan and update their payment details.</p>
          <div className="image-holder">
            <img className="checkout-account-management-img" src={require("../images/checkout-account-management-img.png")} alt="Logo" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CheckoutAccountManagement
