import React from 'react'
import {Link} from 'gatsby'

const BadgeComponentCreditRepair = ({layerOne, layerTwo, layerThree}) => {
  return (
    <div className="badges-wrapper credit-repair">
      <div className="badge-holder"> 
        {layerOne.map((badge, i) => {
          return (
            <div className="badge-item" key={i}>
              <img src={badge.img} className="badge-logo" alt={badge.altText}/>
            </div>
          )
        })}
        </div>
        <div className="badge-holder">    
          {layerTwo.map((badge, j) => {
            return (
              <div className="badge-item" key={j}>
                <img src={badge.img} className="badge-logo" alt={badge.altText}/>
              </div>
            )
          })}
        </div>
        <div className="badge-holder">  
          {layerThree.map((badge, j) => {
            return (
              <div className="badge-item" key={j}>
                <img src={badge.img} className="badge-logo" alt={badge.altText}/>
              </div>
            )
          })}
        </div>
    </div>
  )
}

export default BadgeComponentCreditRepair
