import React from "react"
import SEO from "../../components/seo"
import Layout from "../../components/layout"
import TwoColumn from "../../components/two-column"
import BadgeComponentCreditRepair from "../../components/badge-component-credit-repair"
import Testimonial from "../../components/testimonial"
import FreeTrial from "../../components/free-trial"
import PlanAction from '../../components/plan-action'
import CustomerJourney from '../../components/customer-journey'
import CheckoutAccountManagement from '../../components/checkout-account-management'

import {
  bannerContent, 
  trialContent,
  planActionContent,
  customerJourneyContent,
  authorizeContent,
  businessGrowth,
  noWebsite,
  discounts
} from "../../lib/credit-repair-cloud-content"

const badgeData = [
  {
    img: require("../../images/easiest-admin-new.svg"),
    altText: 'easiest-admin-new',
  },
  {
    img: require("../../images/easiest-to-use-new.svg"),
    altText: 'easiest-to-use-new',
  },
]

const badgeData2 = [
  {
    img: require("../../images/users-most-likely-to-recommend-new.svg"),
    altText: 'users-most-likely-to-recommend-new',
  },
  {
    img: require("../../images/fastest-implementation-new.svg"),
    altText: 'fastest-implementation-new',
  },
  {
    img: require("../../images/best-meets-requirements-new.svg"),
    altText: 'best-meets-requirements-new',
  },
]

const badgeData3 = [
  {
    img: require("../../images/best-support-new.svg"),
    altText: 'best-support-new',
  },
  {
    img: require("../../images/easiest-to-do-business-with-new.svg"),
    altText: 'easiest-to-do-business-with-new',
  },
  {
    img: require("../../images/easiest-to-do-business-with-new.svg"),
    altText: 'easiest-to-do-business-with-new',
  },
  {
    img: require("../../images/easiest-setup-new.svg"),
    altText: 'easiest-setup-new',
  },
  {
    img: require("../../images/best-roi-new.svg"),
    altText: 'best-roi-new',
  },
]

const footerCTAContent = {
  ctaBtnUrl: "https://app.billsby.com/registration?key=0DR563TVMX",
  linkIsExternal: false,
}

const signUpBtnConfig = {
  url: "https://app.billsby.com/registration?key=0DR563TVMX",
  linkIsExternal: false
}

const CreditRepairCloud = () => {
  return (
    <Layout
      isCreditRepairPage
      isHeaderTransparent
      footerLayout3
      footerUrlAlt={footerCTAContent.ctaBtnUrl}
      footerCTAContent={footerCTAContent}
      signUpUrlAlt={signUpBtnConfig.url}
      signUpBtnConfig={signUpBtnConfig}
    >
      <SEO 
        title="Billsby | Subscription billing software for recurring payments | $5k free trial" 
        description="Rely on Billsby for easy and accurate recurring payments. The most powerful, customizable and easy-to-integrate subscription billing software." 
        url="https://www.billsby.com/landing/credit-repair-cloud"
      />
      <div className="credit-repair-cloud">
        <div className="upper-badge">
          <TwoColumn content={bannerContent}/>
          <BadgeComponentCreditRepair layerOne={badgeData} layerTwo={badgeData2} layerThree={badgeData3}/>
        </div>
        <CheckoutAccountManagement />
        <TwoColumn content={authorizeContent}/>
        <CustomerJourney content={businessGrowth} />
        <CustomerJourney content={noWebsite} />
        <CustomerJourney content={discounts} />
        <PlanAction content={planActionContent} />
        <CustomerJourney content={customerJourneyContent} />
        <Testimonial />
        <FreeTrial content={trialContent}/>
      </div>
    </Layout>
  )
}

export default CreditRepairCloud
